import { useState, useEffect, useRef } from "react";

export default function Modal({ isCorrect, solution, turn, callback }) {
  return (
    <div className="modal">
      {isCorrect && (
        <div className="modalbg">
          <h1>You Win!</h1>
          <p className="solution">{solution}</p>
          <p>You found the solution in {turn} guesses!</p>
          <AgainButton callback={callback} />
        </div>
      )}
      {!isCorrect && (
        <div className="modalbg">
          <h1>Awww...too bad</h1>
          <p>
            The solution is: <span className="solution">{solution}</span>
          </p>
          <p>
            <b>Better luck next time!</b>
          </p>
          <AgainButton callback={callback} />
        </div>
      )}
    </div>
  );
}

function AgainButton({ callback }) {
  const isMounted = useRef(false);

  const [showAgainBtn, setShowAgainBtn] = useState(false);

  useEffect(() => {
    if (!isMounted.current) {
      isMounted.current = true;
      // Delay showing again button (timing issue)
      const id = setTimeout(() => setShowAgainBtn(true), 2000);
    }
  }, [showAgainBtn]);

  return (
    <p
      style={{
        textAlign: "right",
        height: "22px",
      }}
    >
      {showAgainBtn && (
        <input
          autoFocus
          type="button"
          id="btnAgain"
          className="restartButton"
          value="Again"
          onClick={callback}
        />
      )}
    </p>
  );
}
