import { useContext } from "react";
import { AppContext } from "../App";

export default function Stats() {
  const { stats } = useContext(AppContext);
  const { wins, losses, avgGuesses } = stats;

  let pct = 0;
  if (wins > 0) {
    pct = Math.round((wins / (wins + losses)) * 100 * 10) / 10;
  }

  // let avgGuessesPerWord = 0;
  // let games = wins + losses;
  // if (games === 0) games = 1;

  // avgGuessesPerWord = Math.round((totalGuesses / games) * 10) / 10;

  return (
    <div className="stats">
      <span className="winLbl">Wins:</span> {wins}&nbsp;&nbsp;
      <span className="lossLbl">Losses:</span> {losses} (
      <span className={pct >= 50 ? "winLbl" : "lossLbl"}>{pct}%</span>) - Avg
      guesses per word: {avgGuesses}
    </div>
  );
}
