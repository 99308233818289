import React, { useEffect, useState, useContext, useRef } from "react";
import useWordle from "../hooks/useWordle";

// components
import Grid from "./Grid";
import Keypad from "./Keypad";
import Modal from "./Modal";

export default function Wordle({ solution, setSolution }) {
  const {
    currentGuess,
    guesses,
    turn,
    isCorrect,
    usedKeys,
    handleKeyup,
    handleRestart,
  } = useWordle(solution, setSolution);
  const [showModal, setShowModal] = useState(false);

  const isModalPending = useRef(false);

  // Allow clicking virutal keypad for entry
  const handleKeyClick = (e) => {
    handleKeyup({ key: e.target.innerText });
  };

  const handleEnterButton = () => {
    handleKeyup({ key: "Enter" });
  };

  const handleBackspaceButton = () => {
    handleKeyup({ key: "Backspace" });
  };

  useEffect(() => {
    window.addEventListener("keyup", handleKeyup);

    if (isCorrect) {
      if (!isModalPending.current) {
        isModalPending.current = true;
        setTimeout(() => setShowModal(true), 2000);
      }
      window.removeEventListener("keyup", handleKeyup);
    }
    if (turn > 5) {
      if (!isModalPending.current) {
        isModalPending.current = true;
        setTimeout(() => setShowModal(true), 2000);
      }
      window.removeEventListener("keyup", handleKeyup);
    }

    return () => window.removeEventListener("keyup", handleKeyup);
  }, [handleKeyup, isCorrect, turn]);

  return (
    <div>
      <Grid guesses={guesses} currentGuess={currentGuess} turn={turn} />
      <div className="keypadwrapper">
        <div className="enterkey" onClick={handleEnterButton}>
          Enter
        </div>
        <div className="backspacekey" onClick={handleBackspaceButton}>
          Bksp
        </div>
        <Keypad usedKeys={usedKeys} callback={handleKeyClick} />
      </div>
      {showModal && (
        <Modal
          isCorrect={isCorrect}
          turn={turn}
          solution={solution}
          callback={() => {
            setShowModal(false);
            isModalPending.current = false;
            handleRestart();
          }}
        />
      )}
    </div>
  );
}
