import { useContext, useState } from "react";

import { AppContext } from "../App";

const useWordle = (solution, getNewSolution) => {
  const [turn, setTurn] = useState(0);
  const [currentGuess, setCurrentGuess] = useState("");
  const [guesses, setGuesses] = useState([...Array(6)]); // each guess is an array
  const [history, setHistory] = useState([]); // each guess is a string
  const [isCorrect, setIsCorrect] = useState(false);
  const [usedKeys, setUsedKeys] = useState({}); // {a: 'grey', b: 'green', c: 'yellow'} etc

  // TK added stuff for scoring session
  const { stats, setStats } = useContext(AppContext);

  // format a guess into an array of letter objects
  // e.g. [{key: 'a', color: 'yellow'}]
  const formatGuess = () => {
    let solutionArray = [...solution];
    let formattedGuess = [...currentGuess].map((l) => {
      return { key: l, color: "grey" };
    });

    // find any green letters
    formattedGuess.forEach((l, i) => {
      if (solution[i] === l.key) {
        formattedGuess[i].color = "green";
        solutionArray[i] = null;
      }
    });

    // find any yellow letters
    formattedGuess.forEach((l, i) => {
      if (solutionArray.includes(l.key) && l.color !== "green") {
        formattedGuess[i].color = "yellow";
        solutionArray[solutionArray.indexOf(l.key)] = null;
      }
    });

    return formattedGuess;
  };

  const calcAvg = () => {
    // We put calculations based on previous values inside the
    // function argument to setStats() to be sure we are using
    // current values.
    setStats((prevStats) => {
      let games = prevStats.wins + prevStats.losses;
      if (games === 0) games = 1;
      // console.log(
      //   `Wins: ${prevStats.wins}, Losses: ${prevStats.losses}, Games ${games}, Guesses: ${prevStats.totalGuesses}`
      // );

      return {
        ...prevStats,
        avgGuesses: Math.round((prevStats.totalGuesses / games) * 10) / 10,
      };
    });
  };

  // add a new guess to the guesses state
  // update the isCorrect state if the guess is correct
  // add one to the turn state
  const addNewGuess = (formattedGuess) => {
    // Increment our guess count
    setStats((prevStats) => ({
      ...prevStats,
      totalGuesses: prevStats.totalGuesses + 1,
    }));

    console.log(stats.totalGuesses);

    if (currentGuess === solution) {
      setIsCorrect(true);
      setStats((prevStats) => ({ ...prevStats, wins: prevStats.wins + 1 }));
      calcAvg();
    } else if (turn === 5) {
      setStats((prevStats) => ({ ...prevStats, losses: prevStats.losses + 1 }));
      calcAvg();
    }

    setGuesses((prevGuesses) => {
      let newGuesses = [...prevGuesses];
      newGuesses[turn] = formattedGuess;
      return newGuesses;
    });
    setHistory((prevHistory) => {
      return [...prevHistory, currentGuess];
    });
    setTurn((prevTurn) => {
      return prevTurn + 1;
    });
    setUsedKeys((prevUsedKeys) => {
      formattedGuess.forEach((l) => {
        const currentColor = prevUsedKeys[l.key];

        if (l.color === "green") {
          prevUsedKeys[l.key] = "green";
          return;
        }
        if (l.color === "yellow" && currentColor !== "green") {
          prevUsedKeys[l.key] = "yellow";
          return;
        }
        if (l.color === "grey" && currentColor !== ("green" || "yellow")) {
          prevUsedKeys[l.key] = "grey";
          return;
        }
      });

      return prevUsedKeys;
    });
    setCurrentGuess("");
  };

  // handle keyup event & track current guess
  // if user presses enter, add the new guess
  const handleKeyup = ({ key }) => {
    if (key === "Enter") {
      // only add guess if turn is less than 5
      if (turn > 5) {
        console.log("You used all your guesses!");
        return;
      }
      // do not allow duplicate words
      if (history.includes(currentGuess)) {
        console.log("You already tried that word.");
        return;
      }
      // check word is 5 chars
      if (currentGuess.length !== 5) {
        console.log("Word must be 5 letters.");
        return;
      }
      const formatted = formatGuess();
      addNewGuess(formatted);
    }
    if (key === "Backspace") {
      setCurrentGuess((prev) => prev.slice(0, -1));
      return;
    }
    if (/^[A-Za-z]$/.test(key)) {
      if (currentGuess.length < 5) {
        // Convert key to lowercase since our keypad is all lower.
        // Without this, used uppercase letters don't color code.
        setCurrentGuess((prev) => prev + key.toLowerCase());
      }
    }
  };

  const handleRestart = () => {
    setIsCorrect(false);
    setTurn(0);
    setCurrentGuess("");
    setGuesses([...Array(6)]);
    setUsedKeys({});
    setHistory([]);

    getNewSolution();
  };

  return {
    turn,
    currentGuess,
    guesses,
    isCorrect,
    usedKeys,
    handleKeyup,
    handleRestart,
  };
};

export default useWordle;
