import { useEffect, useState, useReducer, createContext } from "react";
import data from "./data/db.json";

import Wordle from "./components/Wordle";
import Stats from "./components/Stats";

export const AppContext = createContext({});

/*
const defaultState = {
  wins: 0,
  losses: 0,
};

const reducer = (state, action) => {
  switch (action.type) {
    case "COUNT_WIN":
      break;
    case "COUNT_LOSS":
      break;
    case "RESET":
      break;
    default:
      throw new Error(`Unexpected action type: ${action.type}`);
  }

  return { ...state, wins: state.wins, losses: state.losses };
};
*/
function App() {
  const getNewSolution = () => {
    const json = data.solutions;

    const randomSolution = json[Math.floor(Math.random() * json.length)];
    // console.log(randomSolution.word);
    setSolution(randomSolution.word);
  };

  const [solution, setSolution] = useState(null);

  const [stats, setStats] = useState({
    wins: 0,
    losses: 0,
    totalGuesses: 0,
    avgGuesses: 0,
  });

  useEffect(() => {
    // fetch("http://localhost:3001/solutions")
    //   .then((res) => res.json())
    //   .then((json) => {
    //     // random int between 0 & 14
    //     const randomSolution = json[Math.floor(Math.random() * json.length)];
    //     setSolution(randomSolution.word);
    //   });

    // const json = data.solutions;

    // const randomSolution = json[Math.floor(Math.random() * json.length)];

    // setSolution(randomSolution.word);
    getNewSolution();
  }, [setSolution]);

  // const [state, dispatch] = useReducer(reducer, defaultState);

  return (
    <div className="App">
      <AppContext.Provider
        value={{
          stats,
          setStats,
        }}
      >
        <div className="title">
          {/* <div style={{ width: "190px", textAlign: "right" }}> */}
          &nbsp;
          {/* <div className="ver">v3.1</div>
          </div> */}
        </div>
        <Stats />

        {solution && (
          <Wordle solution={solution} setSolution={getNewSolution} />
        )}
      </AppContext.Provider>
      <div className="footer">Version 3.2.1</div>
    </div>
  );
}

export default App;

/* 

data we need to track:
  -- solution
    -- 5 letter string, e.g. 'drain'
  -- past guesses
    -- an array of past guesses
    -- each past guess is an array of letter objects [{}, {}, {}, {}, {}]
    -- each object represents a letter in the guess word {letter: 'a', color: 'yellow'}
  -- current guess
    -- string 'hello'
  -- keypad letters
    -- array of letter objects [{key: 'a', color: 'green'}, {}, {} ...]
  -- number of turns
    -- an integer 0 - 6

game process:
  -- entering words:
    -- user enters a letter & a square is filled with that letter
    -- when a user hits delete it deletes the previous letter
    -- when a user hits enter it submits the word
      -- if all squares are not filled with letters then the word is not submitted
      -- if that word has already been used in a prev guess then the word is not submitted
  -- checking submitted words:
    -- each letter is checked to see if it matches to the solution
    -- each letter is assigned a color based on it's inclusion in the solution
      -- exact matches (correct position in the solution) are green
      -- partial matches (in the solution but not the correct position) are yellow
      -- none-matches (not in the solution at all) are grey
    -- the guess is added the grid with the correct colors
    -- the current guess moves to the next row
    -- the keypad letters are updated (colors)
  -- ending the game:
    -- when the guessed word fully matches the solution
      -- modal to say 'well done'
    -- when the user runs out of guesses
      -- modal to say 'unlucky'

*/
